import { useWallet } from "@solana/wallet-adapter-react";
import { FC, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import config from "../../config";
import infoIcon from "../../images/icon-info-circle.svg";
import { stakingService } from "../../services/StakingService";
import { getCurrentStakeEarn, getRemainingStakeTime, getStakingPeriod, isStakeClaimAvailable, lamportsToToken, poolLockDurationToDays } from "../../utils/StakingUtils";
import InfoModal from "../InfoModal/InfoModal";

interface StakePanelProps {
    stake: any
    claimPerformed: (hash: string) => void
    unstakePerformed: (hash: string) => void
}

const StakePanel: FC<StakePanelProps> = (props) => {
    const { publicKey, signAllTransactions, signTransaction } = useWallet();
    const [apyLabelInfo, setApyLabelInfo] = useState<boolean>(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [timeRemaining, setTimeRemaining] = useState<string>();
    const [currentEarn, setCurrentEarn] = useState<number>();

    useEffect(() => {
        setTimeRemaining(getRemainingStakeTime(props.stake));
        const timeRemainingUpdateInterval = setInterval(() => {
            setTimeRemaining(getRemainingStakeTime(props.stake));
            if (isStakeClaimAvailable(props.stake)) {
                clearInterval(timeRemainingUpdateInterval)
            }
        }, 1000);
        setCurrentEarn(getCurrentStakeEarn(props.stake));
        const currentEarnUpdateInterval = setInterval(() => {
            setCurrentEarn(getCurrentStakeEarn(props.stake));
            if (isStakeClaimAvailable(props.stake)) {
                clearInterval(currentEarnUpdateInterval)
            }
        }, 100);
        return () => {
            clearInterval(timeRemainingUpdateInterval)
            clearInterval(currentEarnUpdateInterval)
        };
    }, []);

    const action = async (force: boolean = false) => {
        if (!isStakeClaimAvailable(props.stake) && !force) {
            setIsInfoModalOpen(true);
            return;
        }
        if (props.stake.oldProgram) {
            stakingService.initStaking({ publicKey, signAllTransactions, signTransaction }, config.staking.programIdOld)
        }
        if (isStakeClaimAvailable(props.stake)) {
            const hash = await stakingService.claim(
                props.stake.account.authority,
                props.stake.account.stakeIndex.toNumber(),
                props.stake.account.poolData.account.index,
            )
            props.claimPerformed(hash)
        } else {
            const hash = await stakingService.unstake(
                props.stake.account.authority,
                props.stake.account.stakeIndex.toNumber(),
                props.stake.account.poolData.account.index,
            )
            props.unstakePerformed(hash)
        }
        if (props.stake.oldProgram) {
            stakingService.initStaking({ publicKey, signAllTransactions, signTransaction })
        }
    }

    return <div>
        <div className="content-container-inner">
            <div className='data-block'>
                <div>
                    <div>
                        <label className='no-top-padding'>
                            staking period
                        </label>
                        {getStakingPeriod(props.stake)}
                    </div>
                    <div>
                        <label>tokens staked</label>
                        {lamportsToToken(props.stake.account.amount.toString())} $ENRX
                    </div>
                    <div>
                        <label>
                            APY
                            <Popover
                                isOpen={apyLabelInfo}
                                positions={['right']}
                                padding={10}
                                reposition={false}
                                content={<div>Annual percentage yield</div>}>
                                <span onMouseEnter={() => setApyLabelInfo(true)} onMouseLeave={() => setApyLabelInfo(false)} className="info-icon">
                                    <img src={infoIcon} />
                                </span>
                            </Popover>
                        </label>
                        {props.stake.account.poolData.account.apy}%
                    </div>
                    <div>
                        <label>current earn</label>
                        {currentEarn} $ENRX
                    </div>
                    <div>
                        <label>time left</label>
                        {timeRemaining}
                    </div>
                </div>
            </div>
        </div>
        <div className="container-inner">
            <div className="right-align-items">
                <button onClick={() => action()} className="greenHoverBorder">{isStakeClaimAvailable(props.stake) ? 'claim' : 'unstake'}</button>
            </div>
        </div>
        <InfoModal title="are you sure you want to unstake?"
            isOpen={isInfoModalOpen}
            onClose={() => setIsInfoModalOpen(false)}
            buttonText='unstake'
            buttonDisabled={!checked}
            onButtonClick={() => {
                if (!checked) {
                    return;
                }
                setChecked(false)
                setIsInfoModalOpen(false)
                action(true)
            }}>
            <div className='checkbuttonContainer'>
                <input type='checkbox' required checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} id="checkDiv" />
                <label htmlFor="checkDiv" className='checkBoxText'>I understand, that I won't receive staking rewards if unstaking before the staking period.</label>
            </div>
        </InfoModal>
    </div>;
};

export default StakePanel;
