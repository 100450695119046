import logo from './images/logo.svg';
import logoText from './images/logo-text.svg';
import iconStakingOptions from './images/icon-menu-staking-options.svg';
import iconStaking from './images/icon-menu-staking.svg';
import iconViewContract from './images/icon-menu-view-contract.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from "react-router-dom";
import StakingOptions from './components/StakingOptions/StakingOptions.lazy';
import { useEffect, useState } from 'react';
import WalletFloating from './components/Wallet/WalletFloating';
import StakedAssets from './components/StakedAssets/StakedAssets';
import { useWallet } from '@solana/wallet-adapter-react';
import { stakingService } from './services/StakingService';

function Content(props: any): JSX.Element {
    const { connected, publicKey, signAllTransactions, signTransaction } = useWallet();
    const [menuActive, setMenu] = useState<boolean>(false);
    const [showWallet, setShowWallet] = useState<boolean>(false);
    const [stakePoolsLoading, setStakePoolsLoading] = useState<boolean>(false);
    const [navigateUrl, setNavigateUrl] = useState<string | undefined>(undefined);
    const [stakePools, setStakePools] = useState<any[]>([]);
    const [confirmingStake, setConfirmingStake] = useState<boolean>(false);
    const [confirmingClaim, setConfirmingClaim] = useState<boolean>(false);
    const [confirmingUnstake, setConfirmingUnstake] = useState<boolean>(false);

    const handleLink = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    const walletCallback = () => {
        setNavigateUrl(undefined);
        setShowWallet(false);
    }

    useEffect(() => {
        loadPools();
    }, []);

    const loadPools = () => {
        setStakePoolsLoading(true);
        stakingService.getPools().then((result) => {
            setStakePools(result.sort((a, b) => {
                if (b.account.lockDuration.toNumber() === a.account.lockDuration.toNumber()) {
                    return b.account.apy - a.account.apy;
                }
                return b.account.lockDuration.toNumber() - a.account.lockDuration.toNumber();
            }))
            setStakePoolsLoading(false);
        });
    }

    const transactionPerformed = async (hash: string, setConfirmingState: (arg0: boolean) => void) => {
        setConfirmingState(true);
        await stakingService.confirmTransaction(hash);
        setConfirmingState(false);
    }

    return (
        <Router>
            <WalletFloating show={showWallet} setShow={setShowWallet} cl={walletCallback} redirectTo={navigateUrl}></WalletFloating>
            <div className="root-container">
                <div className='navWrapper'>
                    <nav>
                        <div className="menu-logo">
                            <a href="https://enrex.io"><img className='menu-logo-img' src={logo} /><img className='menu-full-logo-img' src={logoText} /></a>
                        </div>
                        <div className="menu-nav">
                            <ul>
                                {!menuActive &&
                                    <li>
                                        <NavLink to="/home"
                                            onClick={(event) => { loadPools(); return null; }}
                                            activeClassName="selected">
                                            <div className="menu-item-icon"><img src={iconStakingOptions} /></div>
                                            <div className='menuName'>Staking Options</div>
                                        </NavLink >
                                    </li>
                                }
                                <li>
                                    <NavLink to="/stakedAssets"
                                        onClick={(event) => { if (!connected) { setNavigateUrl("/stakedAssets"); setShowWallet(true); return handleLink(event); } return null; }}
                                        activeClassName="selected">
                                        <div className="menu-item-icon"><img src={iconStaking} /></div>
                                        <div className='menuName'>Staked Assets</div>
                                    </NavLink >
                                </li>
                            </ul>
                        </div>
                        <div className="menu-footer">
                            <ul>
                                <li>
                                    <a className='footer-menu-item' href='https://github.com/Enrex-io/staking-contract/blob/main/Audit/Enrex%20Staking%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf' target='_blank'>
                                        <div className="menu-item-icon"><img src={iconViewContract} /></div>
                                        <div className='menuName'>View Contract</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="content-container">
                    <Switch>
                        <Route path="/home">
                            <StakingOptions showWallet={showWallet}
                                setShowWallet={setShowWallet}
                                stakePools={stakePools}
                                stakePoolsLoading={stakePoolsLoading}
                                stakePerformed={(hash) => transactionPerformed(hash, setConfirmingStake)}></StakingOptions>
                        </Route>
                        <Route path="/stakedAssets">
                            <StakedAssets pools={stakePools}
                                confirmingStake={confirmingStake}
                                confirmingClaim={confirmingClaim}
                                confirmingUnstake={confirmingUnstake}
                                claimPerformed={(hash) => transactionPerformed(hash, setConfirmingClaim)}
                                unstakePerformed={(hash) => transactionPerformed(hash, setConfirmingUnstake)}></StakedAssets>
                        </Route>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return (
                                    <Redirect to="/home" />
                                )
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </Router >
    );
}

export default Content;
