import { FC, useEffect, useState } from "react";
import infoIcon from "../../images/icon-info-circle.svg";
import StakePanel from "../StakePanel/StakePanel";
import iconError from '../../images/icon-error.svg';
import { stakingService } from "../../services/StakingService";
import iconPending from "../../images/icon-pending.svg";
import { useWallet } from '@solana/wallet-adapter-react';
import { getStakeEndDate, isStakeClaimAvailable } from "../../utils/StakingUtils";
import config from "../../config";
interface StakedAssetsProps {
    pools: any[]
    confirmingStake: boolean
    confirmingClaim: boolean
    confirmingUnstake: boolean
    claimPerformed: (hash: string) => void
    unstakePerformed: (hash: string) => void
}

const StakedAssets: FC<StakedAssetsProps> = (props) => {
    const { connected, publicKey, signAllTransactions, signTransaction } = useWallet();
    const [stakesLoading, setStakesLoading] = useState<boolean>(false);
    const [oldStakeHidden, setOldStakeHidden] = useState<boolean>(false);
    const [stakes, setStakes] = useState<any[]>([]);

    useEffect(() => {
        loadStakes();
    }, [props.pools, props.confirmingStake, props.confirmingClaim, props.confirmingUnstake]);


    useEffect(() => {
        return () => { stakingService.initStaking({ publicKey, signAllTransactions, signTransaction }); }
    }, []);

    const loadStakes = async () => {
        if (publicKey) {
            setStakesLoading(true);
            stakingService.initStaking({ publicKey, signAllTransactions, signTransaction }, config.staking.programIdOld)
            const oldPools = await stakingService.getPools()
            const oldStakes = (await stakingService.getStakes(publicKey.toString())).map(element => {
                const stake: any = element;
                stake.oldProgram = true;
                stake.account.poolData = oldPools.find((pool) => pool.publicKey.toString() === stake.account.pool.toString())
                return stake;
            })
            const filteredOldStakes = oldStakes.filter((stake) => {
                return !config.staking.hideStakesFromPools.includes(stake.account.poolData.account.vault.toString())
            });
            setOldStakeHidden(filteredOldStakes.length !== oldStakes.length);

            stakingService.initStaking({ publicKey, signAllTransactions, signTransaction })
            const stakes = (await stakingService.getStakes(publicKey.toString())).map((element) => {
                const stake: any = element;
                stake.account.poolData = props.pools.find((pool) => pool.publicKey.toString() === stake.account.pool.toString())
                return stake;
            })

            setStakes([...stakes, ...filteredOldStakes].sort((a, b) => {
                if (isStakeClaimAvailable(a) && !isStakeClaimAvailable(b)) {
                    return -1;
                }
                if (!isStakeClaimAvailable(a) && isStakeClaimAvailable(b)) {
                    return 1;
                }
                return getStakeEndDate(a).getTime() - getStakeEndDate(b).getTime();
            }))
            setStakesLoading(false);
        }
    }

    return <div className="dapp-container">
        <label className="content-container-inner-label">your staked $ENRX</label>
        <div className={'content-container-inner content-container-dark note'}>
            <img src={infoIcon} />
            You won't receive staking rewards if unstaking before the staking period
        </div>
        {oldStakeHidden &&
            <div className={'content-container-inner content-container-dark note'}>
                <img src={infoIcon} />
                All stakes made to 60 days pool till 13th of April are already paid out. Stakes made to 90 days pool till April 13th will be payed out during 4th and 5th of July, please check your wallet.
            </div>
        }
        {(props.confirmingStake || props.confirmingClaim || props.confirmingUnstake) &&
            <div className={'content-container-inner content-container-accent note'}>
                <img src={iconPending} className='rotate' />
                {props.confirmingStake &&
                    <>Stake transaction in progress. Your staked asset will appear here in a few moments.</>}
                {props.confirmingClaim &&
                    <>Claim transaction in progress. Your staked assets and reward will be transfered to your wallet shortly.</>}
                {props.confirmingUnstake &&
                    <>Unstake transaction in progress. Your staked asset will be transfered to your wallet shortly.</>}
            </div>
        }
        {stakes.length > 0 && stakes.map((stake) => {
            return <StakePanel key={stake.publicKey}
                stake={stake}
                claimPerformed={props.claimPerformed}
                unstakePerformed={props.unstakePerformed}></StakePanel>
        })}
        {!stakesLoading && stakes.length === 0 &&
            <div className='horizontalContainer'>
                <img src={iconError} />
                <label className='noPadding'>No stakes found</label></div>
        }
        {stakesLoading &&
            <div className='loadingIndicator dot-flashing h-center'></div>
        }
    </div>;
};

export default StakedAssets;