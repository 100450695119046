import config from "../config";
import { cancelStake, claim, getPools, getStakes, initProgram, stake } from "../staking/api";
import { Connection, PublicKey } from "@solana/web3.js";

class TokenAccountDoesNotExist extends Error { }
class StakingService {
    private connection = new Connection(config.staking.cluster);

    async initStaking(wallet: any, programId: PublicKey = config.staking.programId) {
        initProgram(this.connection, wallet, programId);
    }

    async stake(address: string, amount: number, poolIndex: number) {
        const result = await this.connection.getTokenAccountsByOwner(new PublicKey(address), { mint: config.staking.tokenMint });
        console.log(result)
        if (result.value[0]) {
            const tokenAccount = result.value[0].pubkey;
            return await stake(
                config.staking.tokenMint,
                tokenAccount,
                poolIndex,
                amount
            );
        } else {
            throw new TokenAccountDoesNotExist('Token account not found')
        }
    }

    async confirmTransaction(hash: string) {
        await this.connection.confirmTransaction(hash)
    }

    async claim(address: string | PublicKey, stakeIndex: number, poolIndex: number) {
        const result = await this.connection.getTokenAccountsByOwner(new PublicKey(address), { mint: config.staking.tokenMint });
        if (result.value[0]) {
            const tokenAccount = result.value[0].pubkey;
            return await claim(
                config.staking.tokenMint,
                tokenAccount,
                poolIndex,
                stakeIndex,
            )
        } else {
            throw new TokenAccountDoesNotExist('Token account not found')
        }
    }

    async unstake(address: string | PublicKey, stakeIndex: number, poolIndex: number) {
        const result = await this.connection.getTokenAccountsByOwner(new PublicKey(address), { mint: config.staking.tokenMint });
        if (result.value[0]) {
            const tokenAccount = result.value[0].pubkey;
            return await cancelStake(
                config.staking.tokenMint,
                tokenAccount,
                poolIndex,
                stakeIndex,
            )
        } else {
            throw new TokenAccountDoesNotExist('Token account not found')
        }
    }

    async getPools() {
        return getPools();
    }
    async getStakes(address: string) {
        return getStakes(null, address);
    }

    async test() {
        const pools = await getPools();
        const stakes = await getStakes();
        console.log(pools[0].account.amountReward.toString());
        console.log(stakes);

        // await fundPool(config.staking.tokenMint, new PublicKey('3YDHKHnuMUgAFRGzgjU2mKBgKG1r5gHYCameZRWacwDe'), 1, 1000000)
        // await createState(config.staking.tokenMint)
        // await createPool(48, 1000, new BN(30 * 24 * 3600), config.staking.tokenMint)
    }
}

const stakingService = new StakingService()

export {
    stakingService,
}
